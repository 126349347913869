<!--
This view displays information of activity of fortnite map data over time.
1) the firestore is storing map data under /ScrapFNSnapshotsLight each object key is a timestamp
  get the last item on that list and use it as the default selectedSnapshot
2) display the list of maps contained in the selectedSnapshot, let the user select maps using checkboxes
3) the firestore is storing the /ScrapFNMaps each object key is the islandCode
  get the list of maps from there and use it to display the list of maps
the firestore is storing map data under /ScrapFNSnapshots each object key is a timestamp
each object contains 
"maps": [
          {
            "islandCode": "1760-0152-1306",
            "name": "TILTED ZONE WARS (ALL WEAPONS) ⭐",
            "author": "PrettyBoyyAM",
            "image": "https://cdn-0001.qstv.on.epicgames.com/HCEqtcwuoiAFNdODTG/image/screen_comp.jpeg",
            "playerCount": 5576,
            "tags": ["deathrun","parkour","just for fun","difficulty: easy"]
        },
        ...
]
It displays a graph of the number of players on each map over time.
It uses vanilla Chart.js 2.9.4 library to display the graph, no vue wrapper.
It uses the db.collection to get the data from the firestore.
Let the user pick the starting and end date.
-->
<template>
  <div>
    <b-form-group label="Start Date">
      <b-form-datepicker v-model="startDate" :max="endDate" />
    </b-form-group>
    <b-form-group label="End Date">
      <b-form-datepicker v-model="endDate" :min="startDate" />
    </b-form-group>
    <b-button @click="fetchData">Fetch Data</b-button>
    <canvas id="map-chart"></canvas>
  </div>
</template>
<script>
// Import necessary libraries
import Chart from 'chart.js';
import { db } from '@/services/db';

export default {
  data() {
    return {
      startDate: null,
      endDate: null,
      chart: null,
      mapData: [],
    };
  },
  mounted() {
    // Initialize chart
    const ctx = document.getElementById('map-chart').getContext('2d');
    this.chart = new Chart(ctx, {
      type: 'line',
      data: {
        labels: [],
        datasets: [
          {
            label: 'Player Count',
            data: [],
            backgroundColor: 'rgba(255, 99, 132, 0.2)',
            borderColor: 'rgba(255, 99, 132, 1)',
            borderWidth: 1,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              type: 'time',
              time: {
                unit: 'day',
                displayFormats: {
                  day: 'MMM D',
                },
              },
              ticks: {
                maxRotation: 0,
                autoSkip: true,
                maxTicksLimit: 10,
              },
            },
          ],
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
            },
          ],
        },
      },
    });
  },
  methods: {
    async fetchData() {
      // Get map data from firestore
      const snapshot = await db.collection('ScrapFNSnapshots')
        .where('timestamp', '>=', this.startDate)
        .where('timestamp', '<=', this.endDate)
        .get();
      snapshot.forEach((doc) => {
        const data = doc.data();
        this.mapData.push(...data.maps);
      });
      // Process map data
      const mapCounts = {};
      this.mapData.forEach((map) => {
        if (!mapCounts[map.islandCode]) {
          mapCounts[map.islandCode] = {
            label: map.name,
            data: [],
          };
        }
        mapCounts[map.islandCode].data.push({
          x: new Date(map.timestamp),
          y: map.playerCount,
        });
      });
      // Update chart data
      Object.values(mapCounts).forEach((map) => {
        this.chart.data.datasets.push({
          label: map.label,
          data: map.data,
          backgroundColor: `rgba(${Math.floor(Math.random() * 256)}, ${Math.floor(Math.random() * 256)}, ${Math.floor(Math.random() * 256)}, 0.2)`,
          borderColor: `rgba(${Math.floor(Math.random() * 256)}, ${Math.floor(Math.random() * 256)}, ${Math.floor(Math.random() * 256)}, 1)`,
          borderWidth: 1,
        });
      });
      this.chart.update();
    },
  },
};
</script>
